<div class="row">
    <div class="col d-flex">
        <span class="h3">Doctor Appointments</span>
        <div class="mr-auto ml-2">
            <a routerLink="/me/appointments/payment" class="badge badge-pill
                badge-info"
                *ngIf="cart && cart.items.length">
                {{ cart ? cart.items.length : 0}} unpaid
            </a>
        </div>
        <button class="btn btn-outline-info pull-right rounded-circle"
            (click)="refreshCalendar()">
            <fa-icon [icon]="fa.faSync"></fa-icon>
        </button>
    </div>
</div>
<hr>
<div class="small">

    <!-- <p><span class="badge badge-warning">RESERVED</span><br />The slot is not yet booked since your payment is still
        pending or did not successfull complete.
        <br /><span class="badge badge-primary" style="background-color:
            #1665a1;">BOOKED</span><br />You are book and
        ready to go! Be sure to go online before your appointment time.
        <br /><span class="badge badge-success">OPEN</span><br />Doctor is available
    </p> -->
    <p>
        <span class="badge badge-warning mr-2">RESERVED | ONLINE</span>
        <span class="badge badge-warning">RESERVED | FACE-TO-FACE</span>
        <br />The slot is not yet booked since your payment is still
        pending or did not successfully complete.

        <br /><span class="badge badge-primary mr-2" style="background-color:
            #1665a1;">BOOKED | ONLINE</span>
        <span class="badge badge-primary" style="background-color: #490378;">BOOKED
            | FACE-TO-FACE</span>
        <br />You are booked and ready to go.

        <br /><span class="badge badge-success mr-2">OPEN | ONLINE</span>
        <span class="badge badge-success" style="background-color: #e0984c;">OPEN
            | FACE-TO-FACE</span>
        <br />Doctor is available.
    </p>

</div>
<div class="card calendar-card">
    <div class="card-body">
        <div class="overlay" [ngClass]="{'show': calendarLoading}"></div>
        <div class="overlay" [ngClass]="{'empty': !calendarLoading &&
            displayEmptyMessage}">
            <div class="card m-5 p-5" role="alert" *ngIf="isPatient">
                Your calendar is empty. Find a doctor and book an appointment
            </div>
            <div class="card m-5 p-5" role="alert" *ngIf="!isPatient">
                Your calendar is empty. You have not bookings for this month
            </div>
        </div>

        <div class="alert alert-primary" role="alert">
            <strong>
                Dates and times displayed in the calendar are based on <em>Philippine
                    Standard Time</em>.
                If you are located in a different timezone please use this <a
                    href="https://dateful.com/time-zone-converter"
                    target="_new">tool</a>.
            </strong>
        </div>
        <div class="alert alert-warning small">
            You are viewing this calendar in <strong>Asia/Manila</strong>
            timezone
        </div>
        <!-- <div class="col">
            <span class="small text-danger">
                We detected your device's timezone
                is set
                <strong>{{computerTimezone}}</strong>
            </span>
            <br />
            You are viewing this calendar in
            <input
                [(ngModel)]="timezone"
                (typeaheadOnSelect)="calendarComponent.getApi().refetchEvents()"
                [typeahead]="timezones"
                [ngbTypeahead]="searchTimezone"
                #instance="ngbTypeahead"
                (focus)="focus$.next($any($event).target.value)"
                (click)="click$.next($any($event).target.value)"
            />
            timezone
        </div> -->
        <full-calendar #calendar [options]="calendarOptions"></full-calendar>
    </div>
</div>

<!-- TEMPLATE FOR MODAL -->
<ng-template #eventDetailsTemplate let-c="close" let-d="dismiss"
    class="modal-lg">
    <div class="modal-header">
        <!-- <h4 class="modal-title">{{capitalize(action)}} Appointment</h4> -->
        <h4 class="modal-title">Appointment</h4>
        <button type="button" class="close" aria-label="Close" (click)="d()"
            style="outline:none">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div *ngIf="(action==='add' || action==='edit') && !loading">
            <!-- <div class="row" *ngIf="!saveConfirm">
                <div class="col lead text-center">
                    <strong class="text-capitalize">{{getDayOfWeek(selectedEvent.start_at.date)}}, {{getFullDay(datePicked)}} {{hourSpecific?getLocalTime(datePicked):''}}</strong>
                </div>
            </div> -->
            <div class="row" *ngIf="!saveConfirm">
                <div class="col-sm-4 pr-lg-0">
                    <img src="{{avatar(300, selectedTherapist.avatar)}}"
                        class="img-thumbnail"
                        *ngIf="selectedTherapist" />
                </div>
                <div class="col-sm-8">
                    <div class="form-group mb-1">
                        <label for="therapist">Doctor</label>

                        <div *ngIf="openDoctorSearchField ||
                            !isSelectedDoctorValid()">
                            <input #searchDoctor id="typeahead-http" type="text"
                                class="form-control"
                                [(ngModel)]="model" [ngbTypeahead]="search"
                                [focusFirst]="true" [resultTemplate]="rt"
                                (selectItem)="selectDoctor($event)"
                                placeholder="Therapist" />

                            <ng-template #rt let-r="result" let-t="term">
                                <ngb-highlight [result]="r.first_name + ' ' +
                                    r.last_name" [term]="t"></ngb-highlight>
                            </ng-template>
                            <div *ngIf="searching">Searching...</div>
                        </div>
                        <div *ngIf="!openDoctorSearchField &&
                            isSelectedDoctorValid()">
                            <input type="text" class="form-control" value="{{
                                doctorName(selectedTherapist)}}"
                                disabled />
                            <!-- <div class="small"><a href="javascript:void(0)" class="btn btn-sm" (click)="openSearchField()">Search again</a></div> -->
                        </div>

                    </div>

                    <div class="form-group">
                        <label for="timeslot">Availability</label>
                        <input class="form-control disabled"
                            [value]="selectedTherapist ? triggerSearchTimeslot:
                            'Select Schedule'" disabled
                            *ngIf="timeslots.length === 0 || !selectedTherapist"
                            />

                        <div *ngIf="triggeredFromTherapistPage &&
                            !(timeslots.length === 0 || !selectedTherapist)">
                            <div class="static-date-range form-control">{{
                                displayDateRange(selectedEvent) }}</div>
                            <!-- <input class="form-control disabled" disabled
                                value="{{getFullDay(selectedEvent.start_at)}}
                                {{getLocalTime(selectedEvent.start_at)}} -
                                {{getEndTime(selectedEvent.end_at)}}"
                                type="text" /> -->
                        </div>

                        <select class="form-control" name="timeslot"
                            [(ngModel)]="timeslot"
                            *ngIf="!triggeredFromTherapistPage &&
                            timeslots.length"
                            (change)="setSelectedEvent($event)">
                            <option disabled selected value=''>Choose a timeslot</option>
                            <option
                                *ngFor="let timeslot of timeslots"
                                [ngValue]="timeslot">
                                {{displayDateRange(timeslot)}}
                            </option>
                        </select>
                    </div>

                    <div class="small">
                        <strong>{{displayConsultationType(selectedEvent)}}</strong>
                    </div>

                    <div *ngIf="selectedEvent.offline">
                        <div class="small">
                            <strong>Clinic Name: </strong>
                            <span>{{selectedEvent.clinic.name}}</span>
                        </div>
                        <div class="small">
                            <strong>Clinic Adress: </strong>
                            <span>{{ clinicAddress(selectedEvent.clinic) }}</span>
                        </div>
                        <div class="small">
                            <strong>Contact No.: </strong>
                            <span>{{ selectedEvent.clinic.mobile_no }}</span>
                        </div>
                    </div>
                    <div *ngIf="selectedEvent">
                        <span class="small">
                            <strong>Consultation Fee: </strong>
                            <span [innerHTML]="getConsultationFee()"></span>
                        </span>
                    </div>
                    <div class="small">
                        <strong>Add-on:</strong>
                        <br />
                        <div>
                            <label class="form-check-label pl-0">
                                <input type="checkbox"
                                    [disabled]="!checkboxMedCertEnable"
                                    [checked]="appointment.add_ons.medical_certificate"
                                    (click)="onClickMedCert($event)" /> {{
                                label_MedicalCertificate(selectedTherapist)
                                }} <span [innerHTML]="medCertPrice()"></span>
                            </label>
                        </div>

                        <div>
                            <label class="form-check-label pl-0">
                                <input type="checkbox"
                                    [disabled]="!checkboxMedAbstractEnable"
                                    [checked]="appointment.add_ons.abstract_report"
                                    (click)="onClickMedAbstract($event)" /> {{
                                label_MedicalAbstract(selectedTherapist)
                                }} <span [innerHTML]="medAbstractPrice()"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf='saveConfirm && selectedTherapist'>
                <div class="col-md-12 text-center lead mb-2">
                    Are you sure you want to add this appointment?
                </div>
                <!-- <div class="col-md-12 mt-2">
                    <strong>Appointment Summary</strong>
                </div> -->
                <div class="col-md-4 pr-lg-0">
                    <img src="{{avatar(300, selectedTherapist.avatar)}}"
                        class="img-thumbnail"
                        *ngIf="selectedTherapist" />
                </div>

                <div class="col-md-8">
                    <div>
                        Doctor:
                        <strong>{{ getDoctorTitle(selectedTherapist) }}
                            {{selectedTherapist.first_name}}
                            {{selectedTherapist.last_name}}</strong>
                        <br> Date:
                        <strong>{{getFullDay(appointment.event.start_at)}}</strong>
                        <br> Time:
                        <strong>{{getLocalTime(appointment.event.start_at)}}
                            -
                            {{getLocalTime(appointment.event.end_at)}}</strong>
                        <br> Consultation fee:
                        <strong>Php {{ selectedEvent.price }}</strong>
                    </div>
                    <div class="small mt-3">
                        <strong>Add-on Services:</strong>
                        <div *ngIf="appointment.add_ons.medical_certificate">
                            {{ label_MedicalCertificate(selectedTherapist) }}:
                            <span
                                [innerHTML]="medCertPrice()"></span>
                        </div>
                        <div *ngIf="appointment.add_ons.abstract_report">
                            {{ label_MedicalAbstract(selectedTherapist) }}:
                            <span
                                [innerHTML]="medAbstractPrice()"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="(action==='view' || action==='delete') && !loading">
            <div class="row" *ngIf="action==='delete'">
                <div class="col">
                    <p class="lead text-center">
                        <strong>Are you sure you want to cancel this
                            appointment?</strong>
                    </p>
                </div>
            </div>

            <!-- PATIENT's VIEW -->
            <div class="row d-none" *ngIf="selectedTherapist && appointment &&
                appointment.event">
                <div class="col-md-8 offset-md-2">
                    Appointment ID: <strong>{{appointment.event.eventId}}</strong>
                    <br />
                    Therapist:
                    <strong>{{ getDoctorTitle(selectedTherapist) }}
                        {{selectedTherapist.first_name}}
                        {{selectedTherapist.last_name}}</strong>
                    <br> Date: <strong>{{getFullDay(appointment.event.start_at)}}</strong>
                    <br> Time: <strong>{{getLocalTime(appointment.event.start_at)}}
                        -
                        {{getEndTime(appointment.event.end_at)}}</strong>
                </div>

                <div class="col-md-12 text-center mt-4"
                    *ngIf="appointment.event.status=='BOOKED'">
                    <h6>Start Call:</h6>
                    <!-- <button
                        type="button"
                        class="btn {{!canCall(appointment.event.start_at) ? 'btn-default' : 'btn-primary'}} mr-2"
                        [disabled]="!canCall(appointment.event.start_at)"
                        (click)="callUser(selectedTherapist, 0)">
                        <fa-icon [icon]="fa.faPhone"></fa-icon> Voice
                    </button> -->
                    <!-- hasAnsweredQuestionaire =[{{hasAnsweredQuestionaire}}]
                    <br/>
                    canCall:[{{canCall(appointment.event.start_at)}}]
                    <br/>
                    [{{!canCall(appointment.event.start_at) || !hasAnsweredQuestionaire}}] -->
                    <button type="button"
                        class="btn {{!canCall(appointment.event.start_at) ?
                        'btn-default' : 'btn-primary'}} mr-2 wide-btn"
                        [disabled]="!canCall(appointment.event.start_at)"
                        (click)="callUser(appointment.event.id,
                        selectedTherapist, 1)">
                        <fa-icon [icon]="fa.faVideo"></fa-icon> Video
                    </button>
                    <p class="mt-2" *ngIf="isFirstSession">
                        <small class="text-center">* voice call will be
                            available after 1st session</small>
                    </p>
                    <p class="mt-1">
                        <small class="text-center">* Use Headphones for clearer
                            audio</small><br />
                        <small class="text-center">* Please ensure that you are
                            in a quiet and private place while on
                            this call</small>

                    </p>

                    <p class="text-center ">
                        <a class="badge badge-primary btn-tester"
                            href="https://www.speedtest.net/" target="_blank">
                            Internet Speed Test
                        </a>

                        <a class="badge badge-primary btn-tester ml-1"
                            href="https://tokbox.com/developer/tools/precall/"
                            target="_blank">
                            Pre-Call Test
                        </a>
                    </p>

                </div>

                <div class="col-md-12 text-center text-danger mt-2 mb-2"
                    *ngIf="!hasAnsweredQuestionaire">

                    <div class="alert alert-danger m-0">
                        <!-- You are required to answer information sheet before proceeding. -->
                        <div class="p-10">
                            You need to complete your health data sheet before
                            you can proceed with your video call.
                        </div>
                        <div>
                            <button type="button" class="btn btn-danger"
                                [disabled]="canCall(appointment.event.start_at)"
                                (click)="goToQuestionaire()"
                                style="cursor: pointer;">
                                Click here to complete your Information Sheet.
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Doctor's view -->
        <!-- <div *ngIf="action==='patient'  && !loading && doctorView.event.booking"> -->
        <div
            *ngIf="(action==='patient' || action==='view') && !loading &&
            ((appointment && appointment.event && appointment.event.booking) ||
            (appointment && appointment.event.doctor))">
            <!-- <div class="row">
                <div class="col">
                    <p class="lead text-center">
                        <strong>Appointment Details</strong>
                        <br/><small style="font-size:60%;display:block;">(#{{doctorView.event.timeslot_id}})</small>
                    </p>
                </div>
            </div> -->
            <div class="row">

                <div class="col-md-4" [ngClass]="{'d-none':
                    !appointment.event.booking}">
                    <img src="{{avatar(300,
                        appointment.event.booking.patient.avatar)}}" width=""
                        class="img-thumbnail"
                        *ngIf="appointment.event && appointment.event.booking &&
                        action==='patient'" />
                    <img src="{{avatar(300, appointment.event.doctor.avatar)}}"
                        width="" class="img-thumbnail"
                        *ngIf="appointment.event && action==='view'" />
                </div>
                <div class="col-md-8" [ngClass]="{'offset-md-2':
                    !appointment.event.booking}">

                    <div *ngIf="appointment.event.doctor &&
                        appointment.event.status == 'OPEN'">
                            <strong>{{displayConsultationType(appointment.event)}}</strong>
                    </div>
                    <div *ngIf="appointment.event.status == 'BOOKED'">
                            <strong>{{displayConsultationType(appointment.event)}}</strong>
                    </div>

                    <div *ngIf="appointment.event.status == 'RESERVED'">
                        <strong>{{displayConsultationType(appointment.event)}}</strong>
                    </div>

                    Appointment No.: <strong>{{appointment.event.timeslot_id}}</strong>
                    <div *ngIf=" (appointment.event.status == 'BOOKED') &&
                        (appointment.event.offline)">
                        <!-- <div><strong>Face-to-face Consultation</strong> </div> -->
                        <hr>
                        <div> Clinic Name: <strong>{{appointment.event.clinic_id.name}}</strong></div>
                        <div> Clinic Address: <strong>{{clinicAddress(appointment.event.clinic_id)}}</strong></div>
                        <div> Contact No.: <strong>{{appointment.event.clinic_id.mobile_no}}</strong></div>
                    </div>

                    <div *ngIf="appointment.event.status == 'RESERVED' && appointment.event.offline &&
                    action==='patient'">
                        <hr>
                        <div> Clinic Name: <strong>{{appointment.event.clinic_id.name}}</strong></div>
                        <div> Clinic Address: <strong>{{clinicAddress(appointment.event.clinic_id)}}</strong></div>
                        <div> Contact No.: <strong>{{appointment.event.clinic_id.mobile_no}}</strong></div>
                    </div>

                    <div *ngIf="appointment && appointment.event.booking &&
                        action==='patient'">
                        <hr>
                        Patient:
                        <strong *ngIf="appointment.event.booking">{{appointment.event.booking.patient.first_name}}
                            {{appointment.event.booking.patient.last_name}}</strong>
                    </div>

                    <div *ngIf="appointment && appointment.event.doctor &&
                        action==='view'">
                        <hr>
                        Doctor:
                        <strong *ngIf="appointment.event">{{appointment.event.doctor.first_name}}
                            {{appointment.event.doctor.last_name}}</strong>
                    </div>
                    <div>
                        Consultation Date: <strong
                            *ngIf="appointment.event &&
                            appointment.event.start_at">{{getFullDay(appointment.event.start_at)}}</strong>
                        <br> Time: <strong
                            *ngIf="appointment.event &&
                            appointment.event.start_at &&
                            appointment.event.end_at">{{getLocalTime(appointment.event.start_at)}}
                            - {{getEndTime(appointment.event.end_at)}}</strong>
                    </div>
                    <div class="mt-2" *ngIf=" (appointment.event.status ==
                        'BOOKED') && (!appointment.event.offline) ">

                        <div class="small mb-2">
                            <hr>
                            <strong>Add-on Services:</strong>
                            <br />
                            <app-appointment-list-addons-dr-services
                                [doctor]="appointment.event.doctor"
                                [action]="action" [event]="appointment.event"></app-appointment-list-addons-dr-services>
                        </div>
                    </div>

                    <div *ngIf=" (appointment.event.status == 'BOOKED') &&
                        (appointment.event.offline) ">
                        <hr>
                        <div class="small mb-2">
                            <strong>Add-on Services:</strong>
                            <br />
                            <app-appointment-list-addons-dr-services
                                [doctor]="appointment.event.doctor"
                                [action]="action" [event]="appointment.event"></app-appointment-list-addons-dr-services>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 text-center text-danger mt-2 mb-2"
                        *ngIf="!hasAnsweredQuestionaire &&
                        action==='view'">

                        <div class="alert alert-danger m-0">
                            <!-- You are required to answer information sheet before proceeding. -->
                            <div class="p-10 mb-3">
                                You need to complete your health data sheet before
                                you can proceed with your video call.
                            </div>
                            <div>
                                <button type="button" class="btn btn-danger"
                                    [disabled]="canCall(appointment.event.start_at)"
                                    (click)="goToQuestionaire()"
                                    style="cursor: pointer;">
                                    Click here to complete your Information Sheet.
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

        <div class="form-group text-center m-5" *ngIf="loading">
            <!-- <i class='fas fa-pulse fa-spinner fa-3x'></i> --> LOADING
        </div>
    </div>

    <div class="modal-footer text-center d-inline" *ngIf="!loading">
        <div class="row" *ngIf="action==='view' && !confirm">
            <!-- <div class="small"style="line-height:30px">If you wish to cancel or reschedule please contact <a target="_blank" href="mailto:info@recoveryhub.ph">info@recoveryhub.ph</a></div> -->
            <div class="col">
                <button type="button" class="btn btn-sm disabled btn-secondary"
                    *ngIf="selectedEvent.status=='RESERVED' &&
                    selectedEvent.gateway=='paymaya'"
                    (click)="action='delete'; confirm=true" [disabled]="true">
                    Payment Pending
                </button>
                <button type="button" class="btn btn-outline-danger btn-sm ml-1"
                    *ngIf="selectedEvent.status=='RESERVED' &&
                    selectedEvent.gateway!='paymaya'"
                    (click)="action='delete'; confirm=true">
                    Cancel appointment
                </button>
                <!-- <button type="button" class="btn btn-outline-primary btn-sm" (click)="action='edit'; confirm=true">Edit</button> -->
                <button type="button" class="btn btn-warning btn-sm ml-1"
                    style="cursor: pointer;" (click)="c()"
                    *ngIf="!confirm">Close</button>
            </div>
        </div>
        <div *ngIf="action==='delete' && confirm">
            <button type="button" class="btn btn-outline-primary btn-sm"
                (click)="delete()">Yes</button>
            <button type="button" class="btn btn-outline-dark btn-sm ml-1"
                (click)="c()">No</button>
        </div>
        <div *ngIf="action==='edit' && confirm">
            <button type="button" class="btn btn-outline-primary btn-sm"
                (click)="update()">Update</button>
            <button type="button" class="btn btn-warning btn-sm ml-1"
                (click)="c()">Close</button>
        </div>
        <div *ngIf="action==='add'">
            <!-- {{ selectedTherapist |json }} <br/> - <br/>[ {{  timeslot }}] -->
            <!-- [disabled]="!selectedTherapist || !timeslot" -->
            <!-- added back button in adding appointment -->
            <!-- <button 
                type="button" 
                class="btn btn-primary btn-sm" 
                (click)="createAppointment()" 
                *ngIf="!saveConfirm"
                [disabled]="!selectedTherapist || (!timeslot)"
            >
                <fa-icon [icon]="fa.faCartPlus"></fa-icon> Add Appointment
            </button> -->
            <button type="button" class="btn btn-warning btn-sm mr-1"
                (click)="d();backBtn()" *ngIf="!saveConfirm">
                Back
            </button>

            <button type="button" class="btn btn-warning btn-sm mr-1"
                (click)="saveConfirm= false" *ngIf="saveConfirm">
                Back
            </button>

            <button type="button" class="btn btn-primary btn-sm"
                (click)="scheduleAndPay()"
                [disabled]="!selectedTherapist || (!timeslot)"
                *ngIf="!saveConfirm">
                <fa-icon [icon]="fa.faCashRegister"></fa-icon> Schedule &amp;
                Pay
            </button>
        </div>
        <div *ngIf="action==='patient'">
            <button type="button" class="btn btn-primary btn-sm cursor-pointer
                ml-1"
                *ngIf="!isPatient && appointment.event.booking"
                (click)="openWindow('/me/secretary/patients/' +
                appointment.event.booking.patient.id + '?log=recent', '_blank')"
                target="_blank">
                <fa-icon [icon]="fa.faClipboard"></fa-icon> Patient Record
            </button>
            <button type="button" class="btn btn-warning btn-sm cursor-pointer
                ml-1" (click)="c()">Close</button>
        </div>
        <!-- -->
    </div>
</ng-template>

<ng-template #itemTemplate let-model="item" let-index="index">
    {{ model.name }}
</ng-template>

<ng-template #callTemplate let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="form-group">
            <label for="receiver">Call user:</label>
            <input type="text" class="form-control" id="receiver"
                placeholder="Input user id"
                [(ngModel)]="remoteUserId">
        </div>

        <button type="button" class="btn btn-primary" (click)="callUser()">call</button>
    </div>
</ng-template>
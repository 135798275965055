import { Component, OnInit, Injector, ViewChild, ElementRef } from "@angular/core";
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { ToastrManager } from "ng6-toastr-notifications";
import moment from "moment";
import "moment-timezone";
import "fullcalendar";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { AvailabilityService, UserService, calendarEventStatusBGColor, createMoment } from "../../../services";
import { LoaderService } from "../../../services/loader.service";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { faSync, faVideo, faUsers, faClinicMedical } from "@fortawesome/free-solid-svg-icons";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Subject, merge, OperatorFunction, Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from "rxjs/operators";
@Component({
    selector: "app-availability",
    templateUrl: "./availability.component.html",
    styleUrls: ["./availability.component.scss"],
})
export class AvailabilityComponent implements OnInit {
    @ViewChild("eventDetailsTemplate") eventDetailsTemplate: ElementRef;
    @ViewChild("calendar") calendarComponent: FullCalendarComponent;
    @ViewChild("confirmUpdate") confirmUpdate: ElementRef;
    @ViewChild("instance", { static: true }) instance: NgbTypeahead;

    fa = {
        faSync,
        faVideo,
        faUsers,
        faClinicMedical,
    };

    public calendarOptions: any;
    public toastr: any;
    public modal: any;
    public modalConfirmUpdate: any;
    public datePicked: any;
    public start_at = { hour: 8, minute: 0 };
    public end_at = { hour: 17, minute: 0 };
    public label: string;
    public availabilityService: any;
    public selectedEvent: any;
    public confirm: boolean;
    public loading: boolean;
    public addEvent: boolean;
    public loaderService: LoaderService;
    public user;
    public calendarLoading = false;
    public savingEvent: boolean = false;
    public rateCharge;
    public has1hour: boolean;
    public has30min: boolean;
    public slotDetails: any = {};
    public actionMode = ""; // create/edit/delete
    private userService: UserService;
    private duration: any;
    private days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    private days30min = [
        "monday_30min",
        "tuesday_30min",
        "wednesday_30min",
        "thursday_30min",
        "friday_30min",
        "saturday_30min",
        "sunday_30min",
    ];

    timezone: string;
    timezones: string[];
    computerTimezone: string;

    constructor(injector: Injector, private modalService: NgbModal, private http: HttpClient) {
        // this.modalService = injector.get(NgbModal)
        this.availabilityService = injector.get(AvailabilityService);
        this.toastr = injector.get(ToastrManager);
        this.loaderService = injector.get(LoaderService);
        this.userService = injector.get(UserService);

        this.user = this.userService.getStoredCredentials();

        this.resetSloteDetails();

        this.duration = 60;
        this.has1hour = true;
        this.has30min = true;
        this.getRates();
    }

    slotDetailsChange = (slotDetails) => {
        this.slotDetails = { ...this.slotDetails, ...slotDetails };
    };

    ngOnInit() {
        this.resetModal();
        this.timezone = "Asia/Manila"; // moment.tz.guess();
        this.timezones = moment.tz.names();
        this.computerTimezone = "Asia/Manila"; // moment.tz.guess();

        this.calendarOptions = {
            timezone: this.timezone,
            eventClick: this.eventClick,
            dateClick: this.dayClick,
            loading: this.onCalendarLoad,
            events: this.events,
            eventContent: (arg, el) => {
                return {
                    html: arg.event.title,
                };
            },
        };
        this.calendarLoading = false;
    }

    ngAfterViewInit() {}

    getLocalTime = (datetime) => moment(datetime).format("LT");
    getEndTime = (start_at) =>
        moment(start_at || this.start_at)
            .add(1, "h")
            .format("LT");
    getDatePicked = () => moment(this.datePicked).format("MMMM D, YYYY");

    onCalendarLoad = (isLoading, view) => {
        this.loaderService.loader.next(isLoading);
    };

    events = (params, successCallback) => {
        this.calendarLoading = true;
        const r_start = moment(params.start).format("YYYY-MM-DD");
        const r_end = moment(params.end).format("YYYY-MM-DD");

        const str = "start_at:" + r_start + ":>=|start_at:" + r_end + ":<=";

        this.availabilityService
            .clinicHours({
                search: "assignable_id:" + this.user.id,
                dateRange: `${str}`,
                limit: 0,
            })
            .subscribe(
                (result) => {
                    this.calendarLoading = false;
                    // successCallback(result.data.map(({ status, start_at, label, end_at, id, patient }) => ({
                    //     id,
                    //     title: status === 'BOOKED' ? 'BOOKED' : 'AVAILABLE',
                    //     start: moment.tz(start_at.date, start_at.timezone).tz(this.timezone),
                    //     end: moment.tz(end_at.date, end_at.timezone).tz(this.timezone),
                    //     color: status === 'BOOKED' ? '#649d71' : '#528cc9',
                    //     canEdit: status !== 'BOOKED' || patient !== null,
                    //     canDelete: status == 'OPEN' ?  true : false,
                    //     patient
                    // })))

                    successCallback(
                        result.data.map((e) => ({
                            ...this.mapEvents(e),
                            status: e.status,
                            start_at: e.start_at,
                            end_at: e.end_at,
                            id: e.id,
                            // canEdit: e.status !== 'BOOKED',// || e.patient !== null,
                            canEdit: e.dr.id == this.user.id,
                            canDelete: e.status == "OPEN" ? true : false,
                            data: e,
                        }))
                    );
                },
                (error) => {
                    console.log("error", error);
                }
            );
    };

    mapEvents = (timeslot) => ({
        timeslot_id: timeslot.id,
        title: this.eventTitle(timeslot),
        start: createMoment(timeslot.start_at, this.timezone).format("YYYY-MM-DDTHH:mm:ss"),
        end: createMoment(timeslot.end_at, this.timezone).format("YYYY-MM-DDTHH:mm:ss"),
        // backgroundColor: this.eventBackgroundColor(timeslot.status),
    });

    eventTitle(timeslot) {
        let title = `<div class="day-event ${calendarEventStatusBGColor(timeslot)} container">
                        <div class="event-status row">
                          <div class="col-lg-3 text-center p-0 pt-1" style="font-size:1.5em;">${this.getConsultationTypeIcon(
                              timeslot.offline
                          )}</div>
                          <div class="col-lg-9 p-0">
                            <div class="col-lg-12 time p-0 text-left">${this.formattedStartEndTime(
                                timeslot.start_at,
                                timeslot.end_at
                            )}</div>
                            <div class="col-lg-12 status-label p-0">${timeslot.status} | ${this.consultationTypeText(
            timeslot
        )}</div>
                          </div> 
                          
                        </div>
                    </div>`;
        return title;
    }

    getConsultationTypeIcon = (isOffline) => {
        if (isOffline) {
            return icon(this.fa.faClinicMedical).html;
        }

        return icon(this.fa.faVideo).html;
    };

    consultationTypeText = (timeslot) => {
        if (timeslot.offline) {
            return "FACE-TO-FACE";
        }
        return "ONLINE";
    };

    resetSloteDetails = () => {
        this.slotDetails = {
            datetime: null,
            type: null,
            timezone: "Asia/Manila",
            duration: null,
            start_at: null,
            end_at: null,
            clinic_id: null,
            clinic_name: null,
        };
    };

    dayClick = (e) => {
        const date = moment(e.dateStr, "YYYY-MM-DD");

        if (!date.isBefore(moment().startOf("day"))) {
            this.resetModal();
            // console.log('day', date)

            this.datePicked = date;
            if (e.view.type === "agendaDay" || e.view.type === "agendaWeek") {
                this.start_at = { hour: date.hour(), minute: date.minute() };
                this.end_at = { hour: date.add(1, "h").hour(), minute: date.add(1, "h").minute() };
            }
            this.addEvent = true;
            // this.end_at = this.datePicked.add(59, 'm').format('YYYY-MM-DD HH:mm:ss')

            // reset slodeDetails
            this.resetSloteDetails();

            this.actionMode = "create";
            this.modal = this.modalService.open(this.eventDetailsTemplate, { size: "lg" });
            // console.log(this.modal)
        }
    };

    eventClick = (event) => {
        this.resetModal();

        this.selectedEvent = event.event.extendedProps;

        switch (this.selectedEvent.status) {
            // case "BOOKED":
            case "PENDING":
                this.actionMode = "view";
                break;
            case "OPEN":
                this.actionMode = "edit";
                break;

            case "BOOKED":
                this.actionMode = "swap";
                break;
        }
        if (moment(this.selectedEvent.start_at.date).isBefore(moment().tz(this.timezone).startOf("day"))) {
            this.actionMode = "view";
        }

        this.datePicked = moment(this.selectedEvent.start_at.date);
        const shour = moment(this.selectedEvent.start_at.date).hour();
        const sminute = moment(this.selectedEvent.start_at.date).minute();
        const ehour = moment(this.selectedEvent.end_at.date).hour();
        const eminute = moment(this.selectedEvent.end_at.date).minute();
        this.start_at = { hour: shour, minute: sminute };
        this.end_at = { hour: ehour, minute: eminute };
        this.label = this.selectedEvent.title;

        const { clinic, offline, duration, timezone } = this.selectedEvent.data;

        this.slotDetails = {
            ...this.slotDetails,
            ...{
                datetime: this.datePicked,
                type: offline ? "face" : "virtual",
                timezone,
                duration,
                start_at: this.start_at,
                end_at: this.end_at,
                clinic_id: clinic ? clinic.id : null,
                clinic_name: clinic ? clinic.name : null,
            },
        };

        this.modal = this.modalService.open(this.eventDetailsTemplate, { size: "lg" });
    };

    saveEvent = () => {
        // this.loading = true
        const start_at = moment(this.datePicked)
            .tz(this.timezone)
            .hour(this.start_at.hour)
            .minute(this.start_at.minute);
        const end_at = moment(this.datePicked).tz(this.timezone).hour(this.end_at.hour).minute(this.end_at.minute);

        if (this.startDateValidity() && this.endDateValidity()) {
            this.toastr.infoToastr("Invalid time range", "Failed!");
        } else {
            if (start_at && end_at) {
                this.calendarLoading = true;
                this.availabilityService
                    .store({
                        start_at: start_at.format(),
                        end_at: end_at.format(),
                        label: this.label || "available",
                        duration: this.duration,
                    })
                    .subscribe(
                        (response) => {
                            this.calendarLoading = false;
                            this.calendarComponent.getApi().refetchEvents();
                            this.modal.close(this.eventDetailsTemplate);
                            this.toastr.successToastr("Availability has been set", "Saved!");
                        },
                        (error) => {
                            this.calendarLoading = false;
                            this.calendarComponent.getApi().refetchEvents();

                            console.log(error.error, error.error.message);
                            if (error.error.message == "invalid_start_date") {
                                this.toastr.errorToastr(
                                    "There was a problem creating the time slots. Please try again",
                                    "We could not process your request"
                                );
                            } else {
                                console.log(error);
                                this.calendarLoading = false;
                                this.calendarComponent.getApi().refetchEvents();
                                this.modal.close(this.eventDetailsTemplate);
                                this.toastr.errorToastr("Encountered an error while setting availability", "Failed!");
                            }
                        }
                    );
            } else {
                this.toastr.warningToastr("Start time and end time cant be determined", "Warning!");
                this.loading = false;
            }
        }
    };

    saveEventV2 = () => {
        const start_at = moment(this.datePicked)
            .tz(this.slotDetails.timezone)
            .hour(this.slotDetails.start_at.hour)
            .minute(this.slotDetails.start_at.minute);
        const end_at = moment(this.datePicked)
            .tz(this.slotDetails.timezone)
            .hour(this.slotDetails.end_at.hour)
            .minute(this.slotDetails.end_at.minute);

        const data = {
            ...this.slotDetails,
            ...{
                start_at: start_at.format(),
                end_at: end_at.format(),
                label: "available",
                duration: this.slotDetails.duration,
            },
        };

        this.savingEvent = true;

        if (this.actionMode == "edit") {
            this.availabilityService.update(this.selectedEvent.data.id, data).subscribe(
                (response) => {
                    console.log(response);
                    this.savingEvent = false;
                    this.modal.close(this.eventDetailsTemplate);
                    this.toastr.successToastr("Slot updated", "Saved!");
                    this.calendarComponent.getApi().refetchEvents();
                },
                (error) => {
                    this.savingEvent = false;
                    if (error.error.message == "invalid_start_date" || error.error.message == "invalid_same_time") {
                        this.toastr.errorToastr(
                            "Please check your selected schedule",
                            "Veify that your selected schedule is valid or is not in conflict with your other schedules"
                        );
                    } else {
                        this.toastr.errorToastr("Encountered an error while setting availability", "Failed!");
                    }
                }
            );
        } 
        else if (this.actionMode == "swap") {
            this.availabilityService.swapBooked(this.selectedEvent.data.id, data).subscribe(
                (response) => {
                    console.log(response);
                    this.savingEvent = false;
                    this.modal.close(this.eventDetailsTemplate);
                    this.toastr.successToastr("Swap updated", "Saved!");
                    this.calendarComponent.getApi().refetchEvents();
                },
                (error) => {
                    this.savingEvent = false;
                    if (error.error.message == "invalid_start_date" || error.error.message == "invalid_same_time") {
                        this.toastr.errorToastr(
                            "Please check your selected schedule",
                            "Veify that your selected schedule is valid or is not in conflict with your other schedules"
                        );
                    } else {
                        this.toastr.errorToastr("Encountered an error while setting availability", "Failed!");
                    }
                }
            );
        } 
        
        else {
            this.availabilityService.store(data).subscribe(
                (response) => {
                    this.savingEvent = false;
                    this.modal.close(this.eventDetailsTemplate);
                    this.toastr.successToastr("Slot created", "Saved!");
                    this.calendarComponent.getApi().refetchEvents();
                },
                (error) => {
                    this.savingEvent = false;
                    if (error.error.message == "invalid_start_date" || error.error.message == "invalid_same_time") {
                        this.toastr.errorToastr(
                            "Please check your selected schedule",
                            "Veify that your selected schedule is valid or is not in conflict with your other schedules"
                        );
                    } else {
                        this.toastr.errorToastr("Encountered an error while setting availability", "Failed!");
                    }
                }
            );
        }
    };

    startDateValidity = () => {
        const start_at = moment(this.datePicked)
            .tz(this.timezone)
            .hour(this.start_at.hour)
            .minute(this.start_at.minute);

        // console.log('startDateValidity', start_at, this.datePicked)
        return start_at.isSameOrBefore(moment().tz(this.timezone), "h");
    };

    endDateValidity = () => {
        const start_at = moment(this.datePicked)
            .tz(this.timezone)
            .hour(this.start_at.hour)
            .minute(this.start_at.minute);
        const end_at = moment(this.datePicked).tz(this.timezone).hour(this.end_at.hour).minute(this.end_at.minute);

        return end_at.isSameOrBefore(start_at, "minute");
    };

    deleteEvent = () => {
        this.loading = true;
        this.calendarLoading = true;
        this.availabilityService.destroy(this.selectedEvent.timeslot_id).subscribe(
            (response) => {
                this.calendarLoading = false;
                this.modal.close(this.eventDetailsTemplate);
                this.toastr.successToastr(`Availability has been Deleted`, "Success!");
                this.calendarComponent.getApi().refetchEvents();
            },
            (error) => {
                this.calendarLoading = false;
                let msg = `Availability was not Deleted`;
                let title = "Failed";
                if (error.error && error.error.message && error.error.message == "cannot_delete_event") {
                    msg = "Deleting BOOKED or RESERVED schedules is not allowed";
                    title = "Action Not Allowed";
                }
                this.modal.close(this.eventDetailsTemplate);
                this.toastr.errorToastr(msg, title);
            }
        );
    };

    updateEvent = () => {
        if (this.selectedEvent.status == "BOOKED") {
            this.triggerConfirmUpdate();
        } else {
            this.confirmUpdateConfirm();
        }

        // if ( diff_orig == diff_update ) {
        //     this.loading = true
        //     this.calendarLoading = true

        //     this.triggerConfirmUpdate(start_at, end_at)

        //     // this.availabilityService.update(this.selectedEvent.timeslot_id, {
        //     //     start_at: start_at.format('YYYY-MM-DD HH:mm:ss'),
        //     //     end_at: end_at.format('YYYY-MM-DD HH:mm:ss'),
        //     //     label,
        //     //     duration: this.duration
        //     // })
        //     // .subscribe(
        //     //     response => {
        //     //         this.calendarLoading = false
        //     //         this.loading = false
        //     //         this.modal.close(this.eventDetailsTemplate)
        //     //         this.toastr.successToastr('Availability has been Updated', 'Success!')
        //     //         this.calendarComponent.getApi().refetchEvents()

        //     //     },
        //     //     error => {
        //     //         this.calendarLoading = false
        //     //         this.loading = false
        //     //         // console.log('error', error.error.message)

        //     //         if (error.error.message == 'invalid_same_time') {
        //     //             this.toastr.successToastr(`No change in time detected. Nothing to update.`)
        //     //         } else {
        //     //             // this.modal.close(this.eventDetailsTemplate)
        //     //             this.toastr.errorToastr('Please review your selected schedule', `Slot was not created`)
        //     //             // this.calendarComponent.getApi().refetchEvents()
        //     //         }
        //     //     }
        //     // )

        // } else {
        //     this.calendarLoading = false
        //     this.modal.close(this.eventDetailsTemplate)
        //     this.toastr.errorToastr('Please Check Duration', 'Failed!')
        //     // this.calendarComponent.getApi().refetchEvents()
        // }
    };

    resetModal = () => {
        this.start_at = { hour: 8, minute: 0 };
        this.label = undefined;
        this.selectedEvent = undefined;
        this.confirm = false;
        this.addEvent = false;
        this.loading = false;
    };

    getRates() {
        const url = environment.serverUrl + "rate-charges/doctor/" + this.user.id;
        this.http
            .get(url)
            .map((res: any) => res)
            .subscribe((res: any) => {
                this.rateCharge = res.data;
                if (this.rateCharge.use_default) {
                    if (this.rateCharge.default && this.rateCharge.default <= 0) {
                        this.has1hour = false;
                    }
                } else {
                    this.days.forEach((value, key) => {
                        if (!this.rateCharge[value] || this.rateCharge[value] <= 0) {
                            this.has1hour = false;
                        }
                    });
                }

                if (this.rateCharge.use_default_30min) {
                    if (this.rateCharge.default_30min && this.rateCharge.default_30min <= 0) {
                        this.has30min = false;
                    }
                } else {
                    this.days30min.forEach((value, key) => {
                        if (!this.rateCharge[value] || this.rateCharge[value] <= 0) {
                            this.has30min = false;
                        }
                    });
                }
            });
    }

    triggerConfirmUpdate = () => {
        this.modalConfirmUpdate = this.modalService.open(this.confirmUpdate);
    };

    confirmUpdateConfirm = () => {
        // this.message = 'Confirmed!'
        if (this.modalConfirmUpdate) {
            this.modalConfirmUpdate.close();
        }

        // This is not working anymore. this.datePciked is null
        let start_at = moment(this.datePicked).hour(this.start_at.hour).minute(this.start_at.minute).seconds(0);
        let end_at = moment(this.datePicked).hour(this.end_at.hour).minute(this.end_at.minute).seconds(0);
        const label = this.label || "available";

        // Soltion
        start_at = moment(this.selectedEvent.start_at.date)
            .hour(this.start_at.hour)
            .minute(this.start_at.minute)
            .seconds(0);
        end_at = moment(this.selectedEvent.end_at.date).hour(this.end_at.hour).minute(this.end_at.minute).seconds(0);

        // check diff
        let start_at_orig = moment(this.selectedEvent.start_at.date);
        let end_at_orig = moment(this.selectedEvent.end_at.date);

        let diff_update = moment.utc(end_at, "HH:mm:ss").diff(moment.utc(start_at, "HH:mm:ss"), "minutes");
        let diff_orig = moment.utc(end_at_orig, "HH:mm:ss").diff(moment.utc(start_at_orig, "HH:mm:ss"), "minutes");

        if (diff_orig == diff_update) {
            this.loading = true;
            this.calendarLoading = true;

            this.availabilityService
                .update(this.selectedEvent.timeslot_id, {
                    start_at: start_at.format("YYYY-MM-DD HH:mm:ss"),
                    end_at: end_at.format("YYYY-MM-DD HH:mm:ss"),
                    label: "Available",
                    duration: this.duration,
                })
                .subscribe(
                    (response) => {
                        this.calendarLoading = false;
                        this.loading = false;
                        this.modal.close(this.eventDetailsTemplate);
                        this.toastr.successToastr("Availability has been Updated", "Success!");
                        this.calendarComponent.getApi().refetchEvents();
                    },
                    (error) => {
                        this.calendarLoading = false;
                        this.loading = false;
                        // console.log('error', error.error.message)

                        if (error.error.message == "invalid_same_time") {
                            this.toastr.successToastr(`No change in time detected. Nothing to update.`);
                        } else {
                            // this.modal.close(this.eventDetailsTemplate)
                            this.toastr.errorToastr("Please review your selected schedule", `Slot was not created`);
                            // this.calendarComponent.getApi().refetchEvents()
                        }
                    }
                );
        } else {
            this.calendarLoading = false;
            this.modal.close(this.eventDetailsTemplate);
            this.toastr.errorToastr("Please Check Duration", "Failed!");
            // this.calendarComponent.getApi().refetchEvents()
        }
    };

    confirmDecline(): void {
        // this.message = 'Declined!'
        this.calendarLoading = false;
        this.loading = false;
        this.modalConfirmUpdate.close();
    }

    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    searchTimezone = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map((term) =>
                (term === ""
                    ? this.timezones
                    : this.timezones.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
                ).slice(0, 15)
            )
        );
    };
    formattedStartEndTime = (startTime, endTime) => {
        return `${createMoment(startTime, this.timezone).format("h:mm")}-${createMoment(endTime, this.timezone).format(
            "h:mm A"
        )} ${createMoment(endTime, this.timezone).format("zz")}`;
    };
}

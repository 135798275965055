import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AvailabilityService extends AbstractService {
    endpoint = environment.serverUrl + 'user/events';

    constructor(http: HttpClient) {
        super(http);
    }

    searchDate(query: string) {
        return this.http.get(this.endpoint +  query);
    }

    createSlot(start: any, end: any) {
        const data = {
            start_at: start,
            end_at: end
        };

        return this.http.post(this.endpoint, data);
    }


    getDateRangeEvents(startDate: any, endDate: any, params = {}): Observable<any> {
        let defaultParams = {
            // with: 'notes;bookings.patient',
            dateRange: `start_at:${startDate}:>=|start_at:${endDate}:<=`,
            limit: 0,
        };

        defaultParams = {...defaultParams, ...params}
        // console.log('defaultParams', defaultParams)

        return this.index(defaultParams);
    }


    getDateRangePatientEvents(startDate: any, endDate: any, doctorId: any): Observable<any> {
        const str = 'start_at:' + startDate + ':>=|start_at:' + endDate + ':<=';
        const dateQuery = `doctorId=${doctorId}&dateRange=${str}&limit=1000`;
        return this.index(`${dateQuery}`);
    }

    doctorAppointments(e?: any) {
        let sq = '';
        
        if (e) {
            if (e.offset) {
                sq += '&page=' + (e.offset + 1);
        } else if (e.pageSize) {
                sq += '&take=' + e.pageSize;
            } else if (typeof (e) === 'string') {

                sq += '&' + e;
            } else {
                sq += this.buildQueryString(e) 
            }
        }
        return this.http.get(`${this.endpoint}/doctor-appointments${(sq.match(/^\?/) ? sq : `?${sq}`)}`)
            .map((result: any) => {
                if (result) {
                    return result;
                }
            });
    }

    clinicHours(e?: any) {
        let sq = '';
        
        if (e) {
            if (e.offset) {
                sq += '&page=' + (e.offset + 1);
        } else if (e.pageSize) {
                sq += '&take=' + e.pageSize;
            } else if (typeof (e) === 'string') {

                sq += '&' + e;
            } else {
                sq += this.buildQueryString(e) 
            }
        }
        return this.http.get(`${this.endpoint}/clinic-hours${(sq.match(/^\?/) ? sq : `?${sq}`)}`)
            .map((result: any) => {
                if (result) {
                    return result;
                }
            });
    } 

    requestAddon(id: any, params: any) {
        return this.http.put(this.endpoint + '/request-addon/' + id, params)
            .map((result: any) => {
                return result;
            }, (err: any) => {
                return err;
            });
    }

    swapBooked = (eventId: any, data: any) => {
        return this.http.post(
            `${this.endpoint}/swap-booked/${eventId}`, 
            data,
            {
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Accept': environment.API_HEADER_ACCEPT_v2
            }
            }
        ).map((result: any) => {
            return result;
        }, (err: any) => {
            console.log(err)
            return err;
        });
    }
}



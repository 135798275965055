import { Component, Injectable, OnInit, Injector, ViewChild, ElementRef, NgZone, HostListener } from "@angular/core";
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { ToastrManager } from "ng6-toastr-notifications";
import { AuthenticationService } from "./../../../../core/authentication/authentication.service";

import moment from "moment";
import "moment-timezone";
import * as $ from "jquery";
import * as _ from "lodash";
import "fullcalendar";
import { FullCalendarComponent } from "@fullcalendar/angular";
import {
    faSync,
    faPhone,
    faVideo,
    faCashRegister,
    faClipboard,
    faCartPlus,
    faSignOutAlt,
    faClinicMedical,
} from "@fortawesome/free-solid-svg-icons";
import { icon } from "@fortawesome/fontawesome-svg-core";

import { UserService } from "../../../../services/user.service";
import { DoctorAppointmentService } from "../../../../services/doctor-appointment.service";
import { OpenTokService } from "../../../../services/open-tok.service";
import { LoaderService } from "../../../../services/loader.service";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { DAYS } from "./days.constant";
import { User } from "../../../../shared/models";
import {
    NotesService,
    MentalHealthSheetService,
    AvailabilityService,
    SecretaryService,
    MedicalPractitionerService,
    CartService,
    CouponService,
    doctorName,
    patientName,
    avatar,
    AppointmentService,
    calendarEventStatusBGColor,
    label_MedicalCertificate,
    label_MedicalAbstract,
    getDayOfWeek,
    formattedPrice,
    is30Minutes,
    clinicAddress,
    createMoment,
} from "../../../../services";
import { StorageType, StorageService } from "../../../../services/storage.service";
import { ITEMS, DataItem } from "../../../../shared/components/data-sheet/data-sheet";
import { Observable, Subscription } from "rxjs";
import { of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from "rxjs/operators";
import { Location } from "@angular/common";
import { diffDay } from "fullcalendar/src/util";
import { Subject, merge, OperatorFunction } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable()
export class WikipediaService {
    constructor(private http: HttpClient, public doctorsService: MedicalPractitionerService) {}

    search(term: string) {
        if (term === "") {
            return of([]);
        }

        return this.doctorsService
            .index({
                filter: "id",
                search: term,
                orderBy: "last_name",
                sortedBy: "asc",
                with: "rateCharge",
                limit: 0,
            })
            .pipe(
                map((response) => {
                    return response.data;
                })
            );
    }
}

enum CALENDAR_LAYOUT { LIST_MONTH = 'listMonth', DAY_GRID_MONTH = 'dayGridMonth' }


@Component({
    selector: "app-appointment-list",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.scss"],
    providers: [WikipediaService],
})
export class ListSecComponent implements OnInit {
    @ViewChild("eventDetailsTemplate") eventDetailsTemplate: ElementRef;
    @ViewChild("callTemplate") callTemplate: ElementRef;
    @ViewChild("searchTherapist") searchTherapist: NgbTypeahead;
    @ViewChild("calendar") calendarComponent: FullCalendarComponent;
    @ViewChild("instance", { static: true }) instance: NgbTypeahead;

    fa = {
        faSync,
        faPhone,
        faVideo,
        faClipboard,
        faCartPlus,
        faSignOutAlt,
        faCashRegister,
        faClinicMedical,
    };
    storageType = StorageType.LOCAL;
    public service: any;
    public toastr: any;
    public modal: any;
    public modalService: any;
    public otService: any;
    public doctorApptService: any;
    public datePicked: any;
    public start_at = { hour: 8, minute: 0 };
    public end_at: any;
    public selectedEvent: any;
    public confirm: boolean;
    public loading: boolean;
    public selectedTherapist: any;
    public timeslot: any;
    public timeslots = [];
    public filteredEvents: any[];
    public zone: any;
    public selectedEventId: number;
    public selectedBookingId: number;
    public searching: boolean = false;
    public doctor

    displayEmptyMessage: boolean = false;
    selected: string;
    timezone: string;
    timezones: string[];
    computerTimezone: string;
    remoteUserId: any;
    thisUserId: any;
    doctorsList: any[];
    doctorsNames: string[];

    action: any;
    canDoAction: boolean;
    canEdit: boolean;
    includeAfter: boolean;
    hourSpecific: boolean;
    saveConfirm: boolean;
    calendarIsLoading = true;
    calendarOptions: any;
    hasAnsweredQuestionaire: boolean;
    isFirstSession: boolean;
    isFirstSessionWithPatient = false;
    disableCallIfQuestionaireIsNotAnswered = false;

    openDoctorSearchField = true;
    model: any;
    searchFailed = false;
    selectedDoctor = null;
    selectedDate = null;

    checkboxMedCertEnable = false;
    checkboxMedAbstractEnable = null;

    appointment: {
        therapist_details: any;
        add_ons: any;
        event: any;
        price?: any;
        sku?: any;
    };

    eventsData: any[];
    cart: any;
    coupon: string;
    paymentMethod: string;
    addCoupon: boolean;
    currentUser: User;
    isPatient: boolean;

    items: DataItem[] = ITEMS;
    reqItems: any;
    eventId: number;

    addToCartSubscriotion: Subscription;
    calendarLoading = true;

    triggeredFromTherapistPage = false;

    triggerSearchTimeslot = "searching...";

    doctorName = doctorName;
    patientName = patientName;
    avatar = avatar;
    label_MedicalCertificate = label_MedicalCertificate;
    label_MedicalAbstract = label_MedicalAbstract;
    getDayOfWeek = getDayOfWeek;
    formattedPrice = formattedPrice;
    clinicAddress = clinicAddress;

    private currentSize = null;
    private calendarLayout = new Subject<CALENDAR_LAYOUT>();

    constructor(
        injector: Injector,
        private http: HttpClient,
        private route: Router,
        private activatedRoute: ActivatedRoute,
        private mentalHealthService: MentalHealthSheetService,
        private loaderService: LoaderService,
        private userService: UserService,
        private apptService: AppointmentService,
        public availabilityService: AvailabilityService,
        public secretaryService: SecretaryService,
        private cartService: CartService,
        private searchService: WikipediaService,
        public doctorsService: MedicalPractitionerService,
        private authService: AuthenticationService,
        private couponService: CouponService,
        private location: Location
    ) {
        this.modalService = injector.get(NgbModal);
        this.doctorApptService = injector.get(DoctorAppointmentService);
        this.otService = injector.get(OpenTokService);
        this.toastr = injector.get(ToastrManager);
        this.zone = injector.get(NgZone);
        this.canDoAction = !!this.userService.checkRole(["patient", "in-patient"]);
        this.currentUser = this.userService.getStoredCredentials();
        this.isPatient = this.userService.checkRole(["patient", "in-patient"]);

        this.userService.getUserInfo().subscribe((data: any) => {
            this.isFirstSession = data.isFirstSession || false;
        });

        this.calendarLayout.pipe(distinctUntilChanged()).subscribe((calendarLayout: CALENDAR_LAYOUT) => {
            this.calendarComponent.getApi().changeView(calendarLayout)
        })

        this.reqItems = this.getItems();

        if (this.canDoAction) {
            // CHECK IF THIS IS trigger from the therapist page
            if ((this.eventId = activatedRoute.snapshot.params.eventId)) {
                this.triggeredFromTherapistPage = true;
                this.calendarLoading = true;
                this.availabilityService.show(this.eventId, { with: "doctor.rateCharge;clinic" }).subscribe(
                    (result) => {
                        this.calendarLoading = false;
                        this.selectedTherapist = result.data.doctor;
                        this.openDoctorSearchField = false;
                        delete result.data.doctor;
                        this.selectedEvent = result.data;

                        var start = moment(result.data.start_at.date).startOf("day").format("YYYY-MM-DD HH/mm/ss");
                        var end = moment(result.data.start_at.date).endOf("day").format("YYYY-MM-DD HH/mm/ss");
                        this.getDoctorTimeslots(
                            {
                                search: `status:OPEN`,
                                dateRange: "events.start_at:" + start + ":>=|events.start_at:" + end + ":<=",
                            },
                            this.eventId
                        );

                        this.dayClick({
                            date: result.data.start_at.date,
                            view: {
                                name: "month",
                            },
                        });
                    },
                    (error) => {
                        this.calendarLoading = false;
                        console.log("error in eventid", error);
                    }
                );
                // PULL the record
                // switch the calender to the month
            }

            this.mentalHealthService.getUserData(this.userService.currentUser.id).subscribe((data: any) => {
                if (this.isAnsweredRequired(data.patient_data)) {
                    this.hasAnsweredQuestionaire = true;
                } else {
                    this.hasAnsweredQuestionaire = data.isMentalDataComplete;
                }
            });
        }

        this.paymentMethod = null;
    }

    isAnsweredRequired(data) {
        let track = true;

        this.reqItems.forEach(function (item, key) {
            if (item.key === "reasonForConsultation") {
                if (!data.reasonForConsultation.reasonForConsultation) {
                    track = false;
                }
            } else {
                if (!data.generalInformation[item.key]) {
                    track = false;
                }
            }
        });

        return track;
    }

    ngAfterViewInit() {
        this.onResize()
      }
    
      @HostListener('window:resize')
      @HostListener('window:load')
      onResize() {
        if(this.currentSize === null) {
          if(window.innerWidth <= 1000){
            this.calendarLayout.next(CALENDAR_LAYOUT.LIST_MONTH)
          } else {
            this.calendarLayout.next(CALENDAR_LAYOUT.DAY_GRID_MONTH)
          }
          this.currentSize = window.innerWidth
          return;
        }
         
        if(this.currentSize <= 1000 && window.innerWidth >= 1000) {
          this.calendarLayout.next(CALENDAR_LAYOUT.DAY_GRID_MONTH)
        }
    
        if(this.currentSize >= 1000 && window.innerWidth <= 1000) {
          this.calendarLayout.next(CALENDAR_LAYOUT.LIST_MONTH)
        }
        this.currentSize = window.innerWidth
      }
    

    getItems() {
        return this.items.filter((i) => i.required === true);
    }

    search = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap((item) => {
                this.searching = true;
                this.openDoctorSearchField = true;
            }),
            switchMap((term) =>
                this.searchService.search(term).pipe(
                    tap((item) => {
                        this.searchFailed = false;
                        this.openDoctorSearchField = false;
                    }),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    })
                )
            ),
            tap((item) => {
                this.searching = false;
            })
        );
    };

    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    searchTimezone = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map((term) =>
                (term === ""
                    ? this.timezones
                    : this.timezones.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
                ).slice(0, 15)
            )
        );
    };

    myDoctor = () => {
        this.secretaryService.myDoctor()
          .subscribe(res => {

            this.doctor = res.data
            this.calendarOptions = {
                eventClick: this.eventClick,
                // dateClick: this.dayClick,
                loading: this.onCalendarLoad,
                noEventsContent : { html: '<div class="text-center font-weight-bold">No bookings or appointments to display</div>'},
                contentHeight: 'auto',
                events: this.events,
                eventContent: (arg, el) => {
                    return {
                        html: arg.event.title,
                    };
                },
            };
            // this.getEvents()
          })
      }

    getEvents(params) {
        if (params.dateRange && typeof params.dateRange == "string" && params.dateRange.length) {
            params.dateRange = params.dateRange;
        }

        if (status && typeof status == "string" && status.length) {
            params.search += ";status:" + status;
        }

        return this.secretaryService.getCalendarByDoctorId(this.doctor[0].id,params);
    }
                      
    loadCart() {
        // this.isPatient = !!this.currentUser.roles.find(({ id }) => id === 3);
        this.currentUser = this.userService.getStoredCredentials();

        if (!this.isPatient) {
            return;
        }

        return this.cartService.index().subscribe((cart) => {
            this.cart = cart;
        });
    }

    ngOnInit() {
        this.loadCart();
        this.resetModal();
        this.timezone = moment.tz.guess();
        this.timezones = moment.tz.names();
        this.computerTimezone = moment.tz.guess();

        this.myDoctor()
        // this.calendarOptions = {
        //     eventClick: this.eventClick,
        //     // dateClick: this.dayClick,
        //     loading: this.onCalendarLoad,
        //     events: this.events,
        //     eventContent: (arg, el) => {
        //         return {
        //             html: arg.event.title,
        //         };
        //     },
        // };
    }

    getLocalTime = (datetime) => createMoment(datetime, this.timezone).format("LT");
    getEndTime = (datetime) => createMoment(datetime, this.timezone).format("LT z");
    getFullDay = (datetime) => createMoment(datetime, this.timezone).format("MMM. DD, YYYY");
    capitalize = (str) => _.capitalize(str);

    canCall = (date) => {
        let dateTemp = date;
        let appointment;
        let todayDate;

        if (date.date) {
            dateTemp = date.date;
        }

        appointment = createMoment(date, this.timezone); //  moment(dateTemp, 'YYYY-MM-DD');
        todayDate = moment();

        if (this.isPatient) {
            return appointment.isSame(todayDate, "day") && this.hasAnsweredQuestionaire;
        } else {
            return appointment.isSame(todayDate, "day");
        }
    };

    selectTherapist = (therapistName) => {
        this.selectedTherapist = this.doctorsList.find((dr) => dr.full_name === therapistName);
        if (this.selectedTherapist) {
            this.filterEvents(this.selectedTherapist.events, false);
        }
    };

    onCalendarLoad = (isLoading, view) => {
        this.loaderService.loader.next(isLoading);
    };

    clearSelection = () => {
        this.selectedTherapist = undefined;
    };

    availableSlot = () => {
        return this.filteredEvents.filter((data) => data.status == "OPEN");
    };

    filterEvents = (doctorEvents, includeAfter) => {
        let filteredEvents = [];
        const filterDateString = this.datePicked;

        if (this.hourSpecific) {
            filteredEvents = doctorEvents.filter(({ start_at }) => {
                return (
                    filterDateString.hour() === moment(start_at, "YYYY-MM-DD HH:mm:ss").hour() &&
                    moment(start_at, "YYYY-MM-DD HH:mm:ss").isSame(filterDateString, "day")
                );
            });
        } else {
            if (this.includeAfter) {
                filteredEvents = doctorEvents.filter(({ start_at }) =>
                    moment(start_at, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(filterDateString, "day")
                );
            } else {
                filteredEvents = doctorEvents.filter(({ start_at }) =>
                    moment(start_at.date, "YYYY-MM-DD HH:mm:ss").isSame(filterDateString, "day")
                );
            }
        }
        const afterNextHours = filteredEvents.filter(({ start_at }) => {
            return moment(start_at.date, "YYYY-MM-DD HH:mm:ss").isAfter(moment(), "h");
        });

        this.filteredEvents = _.sortBy(afterNextHours, ["start_at"]);
    };

    openModal = () => {
        // this.resetModal();
        this.modal = this.modalService.open(this.eventDetailsTemplate);
        this.modal.result.then(
            () => {
                // console.log('When user closes');
            },
            () => {
                this.resetModal();
                this.openSearchField();
            }
        );
    };

    events = (time, successCallback, failureCallback) => {
        const r_start = moment(time.start).format("YYYY-MM-DD");
        const r_end = moment(time.end).format("YYYY-MM-DD");
        let days = moment(time.end).diff(moment(time.start), "d", true);

        const startString = moment(r_start).format("YYYY-MM-DD");
        const endString = moment(r_end).format("YYYY-MM-DD");
        days = moment(r_end).diff(moment(r_start), "d", true);

        var params = {
            // filter: "id;end_at;start_at;assignable_id;status;timezone;offline;created_at;",
            // with: "doctor.rateCharge;cartItem_2.order.transactions;",
            dateRange: "events.start_at:" + startString + ":>=|events.start_at:" + endString + ":<=",
            limit: 0, //(days * 8),
            sortedBy: "asc",
        };


        this.calendarLoading = true;
        this.displayEmptyMessage = false;

        this.getEvents(params).subscribe((result) => {
            this.calendarLoading = false;
            this.eventsData = result.data;

            if (!result.data.length) {
                this.displayEmptyMessage = true;
            }

            successCallback(
                result.data.map((e) => ({
                    ...this.mapEvents(e),
                    status: e.status,
                    start_at: e.start_at,
                    end_at: e.end_at,
                    offline: e.offline,
                    doctor: e.doctor ? e.doctor : null,
                    clinic_id: e.clinic,
                    patient: e.patient ? e.patient : null,
                    id: e.id,
                    eventId: e.id,
                    bookingId: e.bookingId,
                    booking: e.bookings,
                    has_started: e.has_started,
                    gateway:
                        e.cart_item_2 && e.cart_item_2.order && e.cart_item_2.order.transactions.length
                            ? e.cart_item_2.order.transactions[0].gateway
                            : "",
                }))
            );
        });
    };

    mapEvents = (timeslot) => ({
        timeslot_id: timeslot.id,
        title: this.eventTitle(timeslot),
        start: createMoment(timeslot.start_at, this.timezone).format("YYYY-MM-DDTHH:mm:ss"),
        end: createMoment(timeslot.end_at, this.timezone).format("YYYY-MM-DDTHH:mm:ss"),
        // backgroundColor: this.eventBackgroundColor(timeslot.status),
    });

    formattedStartEndTime = (startTime, endTime) => {
        return `${createMoment(startTime, this.timezone).format("h:mm")}-${createMoment(endTime, this.timezone).format(
            "h:mm A"
        )} ${createMoment(endTime, this.timezone).format("zz")}`;
    };

    consultationType = (timeslot) => {
        if (timeslot.offline) {
            return icon(this.fa.faClinicMedical).html.join("");
        }
        return icon(this.fa.faVideo).html.join("");
    };

    consultationTypeText(timeslot) {
        if (timeslot.offline) {
            return "FACE-TO-FACE";
        }
        return "ONLINE";
    }

    eventTitle(timeslot) {
        const isPatient = this.userService.checkRole(["patient", "in-patient"]);
        const blinking = this.canCall(timeslot.start_at) && timeslot.status == "BOOKED" ? "blinking" : "";

        let title = `<div class="event-status row">
                    <div class="col-lg-2 ${blinking}">${this.consultationType(timeslot)}</div>
                    <div class="col-lg-9 p-0">
                      <div class="col-lg-12 time p-0 text-left">${this.formattedStartEndTime(
                          timeslot.start_at,
                          timeslot.end_at
                      )}</div>
                      <div class="col-lg-12 status-label p-0">${timeslot.status} | ${this.consultationTypeText(
            timeslot
        )}</div>
                    </div>  
                  </div>`;

        let online = timeslot.opentok
            ? isPatient
                ? timeslot.opentok.host_status
                : timeslot.opentok.attendee_status
            : null;

        let badge = "";
        let patient = null;
        let name = "";
        if (online !== null) {
            badge = this.canCall(timeslot.start_at)
                ? '<br/><span class="day-badge badge badge-' +
                  (online ? "success" : "danger") +
                  '">' +
                  (online ? "ONLINE" : "OFFLINE") +
                  "</span>"
                : "";
        }

        if (timeslot.bookings && timeslot.bookings.patient) {
            patient = patientName(timeslot.bookings.patient.general_information);
        }

        let profilePic = null;
        if (isPatient) {
            profilePic = avatar(300, timeslot.doctor.avatar);
        } else {
            if (timeslot.bookings) {
                profilePic = avatar(300, timeslot.bookings.patient.avatar);
            }
        }

        name =
            `<div class="others-name row">
                    <div class="col-lg-4 pr-lg-0"><img src="${profilePic}" width="" class="w-100 rounded picture" /></div>
                    <div class="col-lg-7 pr-lg-0">` +
            (isPatient ? `${doctorName(timeslot.doctor.general_information)}` : patient) +
            `
                    </div>
                </div>`;

        // IF current is a PATIENT,
        // then display the doctor's name
        if (isPatient) {
            title = `${title}${name}`;
        } else {
            if (timeslot.bookings && timeslot.bookings.patient) {
                title = `${title}${name}`;
            }
        }

        return `<div class="day-event ${calendarEventStatusBGColor(timeslot)} container">${title}</div>`;
    }

    eventBackgroundColor(status) {
        let color = "";
        switch (status.toUpperCase()) {
            case "BOOKED":
                color = "#1665a1";
                break;

            case "RESERVED":
                color = "#fcba03";
                break;

            case "OPEN":
            default:
                color = "#4a9129";
                break;
        }

        return color;
    }

    eventBackgroundColor2(timeslot) {
        let className = "";

        if (timeslot.offline) {
            if (!timeslot.status) {
                return className;
            }

            switch (timeslot.status.toUpperCase()) {
                case "BOOKED":
                    className = "status-booked-off-list";
                    break;

                case "RESERVED":
                    className = "status-reserved-off-list";
                    break;

                case "OPEN":
                default:
                    className = "status-open-off-list";
                    break;
            }
        } else {
            if (!timeslot.status) {
                return className;
            }

            switch (timeslot.status.toUpperCase()) {
                case "BOOKED":
                    className = "status-booked-list";
                    break;

                case "RESERVED":
                    className = "status-reserved-list";
                    break;

                case "OPEN":
                default:
                    className = "status-open-list";
                    break;
            }
        }
        // console.log("className", className)

        return className;
    }

    eventClick = (e) => {
        this.resetModal();
        const event = e.event.extendedProps;
        this.selectedEvent = e.event.extendedProps;

        this.appointment.therapist_details = event.doctor;
        this.appointment.event = event;
        this.selectedTherapist = event.doctor;

        if (this.canDoAction) {
            this.action = "view";
            this.timeslot = event.timeslot_id;
            this.selectedEventId = event.timeslot_id;
            this.selectedBookingId = event.booking.id;
            this.datePicked = event.start;
            this.selectedEvent = event;

            this.loading = true;
            if (event.status == "BOOKED" || event.status == "RESERVED") {
                this.loading = false;
            } else {
                this.createAppointment();
                this.doctorsService.show(event.doctor.id).subscribe(
                    (res) => {
                        this.filterEvents(res.data.events, false);
                        this.loading = false;
                    },
                    (err) => {
                        this.toastr.errorToastr(err.error.message || "Cannot get doctor's schedule", "Failed!");
                        this.loading = false;
                    }
                );
            }

            this.modal = this.modalService.open(this.eventDetailsTemplate);
        } else {
            this.action = "patient";
            this.loading = false;
            this.selectedEventId = event.timeslot_id;
            this.selectedEvent = event;
            this.modal = this.modalService.open(this.eventDetailsTemplate);
        }
    };

    dayClick = (info) => {
        const date = moment(info.date);

        if (!date.isSameOrAfter(moment(), "day")) {
            this.toastr.warningToastr("You have selected a past date. Please select another.");
        } else {
            if (this.canDoAction) {
                this.openModal();
                this.selectedDate = date;
                this.action = "add";
                this.hourSpecific = info.view.name === "agendaWeek";
                this.datePicked = moment(date);
                if (this.selected) {
                    this.selectTherapist(this.selected);
                }

                return true;
            }
        }

        this.triggeredFromTherapistPage = false;
        // this.location.replaceState('/me/appointments');
        this.timeslots = [];
        this.resetModal();
    };

    addAppointmentBtn = () => {
        this.openModal();
        this.action = "add";
        this.includeAfter = true;
        this.datePicked = moment();
    };

    day = (dayNo) => DAYS[dayNo];

    createAppointment = () => {
        if (this.selectedEvent && this.selectedEvent.doctor && this.selectedEvent.doctor.rate_charge) {
            this.selectedTherapist = this.selectedEvent.doctor;
        }

        this.loading = true;

        let minutes = moment(this.selectedEvent.end_at.date).diff(moment(this.selectedEvent.start_at.date), "m", true);
        let qty = minutes > 0 ? minutes / this.selectedTherapist.rate_charge.time_duration : 1;

        const price = !!this.selectedTherapist.rate_charge.use_default
            ? this.selectedTherapist.rate_charge.default
            : this.selectedTherapist.rate_charge[this.day(moment().day())] * qty;

        this.appointment.therapist_details = this.selectedTherapist;
        this.appointment.event = this.selectedEvent;
        this.appointment.price = price;

        this.saveConfirm = true;
        this.loading = false;
    };

    scheduleAndPay() {
        const url = environment.serverUrl;
        this.loading = true;

        if (this.coupon) {
            const code = this.coupon;

            return this.couponService.addCoupon(code).subscribe(
                (data) => {
                    this.loading = false;
                    this.addToCart();
                },
                (e) => {
                    this.loading = false;
                    if (e.error.message === "coupon_already_applied") {
                        this.toastr.errorToastr("Coupon already applied");
                    } else {
                        this.toastr.errorToastr("Coupon is invalid");
                    }
                }
            );
        } else {
            this.addToCart();
            // this.route.navigate(['me/appointments/payment']);
        }
    }

    ngOnDestroy() {
        //    console.log('ngOnDestroy')
    }

    async addToCart() {
        // if (!(this.paymentMethod && this.paymentMethod.length)) {
        //     this.loading = false;
        //     this.toastr.warningToastr('Please select a payment method');
        //     return false;
        // }

        const event = this.selectedEvent; // this.selectedTherapist.events.find(e => e.id === this.timeslot);

        const event_id = event.id;
        const fee = event.price;
        this.loading = true;

        // this.route.navigate(['me/appointments/payment']);
        return this.cartService
            .addEventToCart(event_id, { add_ons: this.appointment.add_ons })

            .subscribe(
                (result) => {
                    let { price, sku } = result;
                    // StorageService.storage(this.storageType).setItem('paymentMethod', this.paymentMethod);
                    this.modal.close(this.eventDetailsTemplate);
                    this.resetModal();
                    this.loadCart();
                    this.removeCoupon();
                    this.toastr.successToastr("Appointment added to cart");
                    this.loading = false;
                    this.route.navigate(["me/appointments/payment"]);
                },
                (error) => {
                    this.loading = false;
                    console.log(error);
                    if (error.error.code == 400) {
                        if (error.error.message == "event_time_passed") {
                            this.toastr.errorToastr("You selected a past schedule");
                        }
                    }
                }
            );
    }

    removeCoupon() {
        delete this.coupon;
        this.addCoupon = false;
    }

    delete = () => {
        this.loading = true;

        this.apptService.cancelAppointment(this.selectedBookingId).subscribe(
            (response) => {
                this.refreshCalendar();
                this.modal.close(this.eventDetailsTemplate);
                this.toastr.successToastr("Appointment has been cancelled", "Success!");
            },
            (response) => {
                this.refreshCalendar();
                this.modal.close(this.eventDetailsTemplate);
                this.toastr.errorToastr(response.error.message || "Appointment was not cancelled", "Failed!");
            }
        );
        // this.apptService.destroy(this.selectedBookingId)
        //     .subscribe(
        //         response => {
        //             this.refreshCalendar();
        //             this.modal.close(this.eventDetailsTemplate);
        //             this.toastr.successToastr('Appointment has been cancelled', 'Success!');
        //         },
        //         response => {
        //             this.refreshCalendar();
        //             this.modal.close(this.eventDetailsTemplate);
        //             this.toastr.errorToastr(response.error.message || 'Appointment was not cancelled', 'Failed!');
        //         }
        //     );
    };

    update = () => {
        this.loading = true;
        this.apptService
            .update(this.selectedEvent.id, {
                event_id: this.timeslot,
            })
            .subscribe(
                (response) => {
                    this.refreshCalendar();
                    this.modal.close(this.eventDetailsTemplate);
                    this.toastr.successToastr("Appointment has been updated", "Success!");
                },
                (response) => {
                    this.refreshCalendar();
                    this.modal.close(this.eventDetailsTemplate);
                    this.toastr.errorToastr("Appointment was not updated", "Failed!");
                }
            );
    };

    openWindow(url, target = "_blank") {
        window.open(url, target);
    }

    // modal controls

    resetModal = () => {
        this.action = undefined;

        this.hourSpecific = false;
        this.datePicked = undefined;
        this.selectedTherapist = undefined;
        this.selectedEvent = undefined;
        this.timeslot = undefined;
        this.timeslots = [];
        this.filteredEvents = [];
        this.includeAfter = false;
        this.confirm = false;
        this.loading = false;
        this.appointment = {
            therapist_details: null,
            add_ons: { medical_certificate: false, abstract_report: false },
            event: null,
            price: null,
            sku: null,
        };
        this.saveConfirm = undefined;
        this.checkboxMedCertEnable = false;
        this.checkboxMedAbstractEnable = null;
    };

    log = (str) => {
        this.modal = this.modalService.open(this.callTemplate);
        // console.log(str);
    };

    callUser(eventId, user: any, type: any) {
        // type; 0 if voice, 1 for video
        if (user) {
            this.modal.close(this.callTemplate);
            this.otService.generateToken({
                id: user.id,
                type,
                name: `${user.first_name} ${user.last_name}`,
                eventId: this.selectedEventId,
            });

            // edit this service to fetch from laravel opentok
            //  waiting for update to connect to client
            // this.otService.callUser({
            //     id: user.id,
            //     type,
            //     name: `${user.first_name} ${user.last_name}`,
            //     eventId: this.selectedEventId
            // });
            // this.modal.close(this.callTemplate);
        }
    }

    goToQuestionaire() {
        this.modal.close(this.eventDetailsTemplate);
        this.route.navigate(["/me/account/questionnaire"]);
    }

    refreshCalendar() {
        this.calendarComponent.getApi().refetchEvents();
    }

    isSelectedDoctorValid() {
        return this.selectedTherapist && typeof this.selectedTherapist == "object" ? true : false;
    }

    selectDoctor(item) {
        this.model = null;
        this.selectedTherapist = item.item;

        var start = this.selectedDate.startOf("day").format("YYYY-MM-DD HH/mm/ss");
        var end = this.selectedDate.endOf("day").format("YYYY-MM-DD HH/mm/ss");

        this.triggerSearchTimeslot = "Searching...";

        // START
        this.getDoctorTimeslots({
            search: `status:OPEN;assignable_id:${this.selectedTherapist.id}`,
            // search: ``,
            dateRange: "events.start_at:" + start + ":>=|events.start_at:" + end + ":<=",
        });
    }

    getDoctorTimeslots(params?, selectedTimeslot?) {
        let defaultParams = {
            orderBy: "events.start_at",
            searchJoin: "and",
            sortedBy: "asc",
            limit: 0,
            with: "doctor.rateCharge",
        };

        // this.triggerSearchTimeslot = '';
        params = { ...defaultParams, ...params };

        return this.availabilityService.index(params).subscribe((result) => {
            this.timeslots = result.data;

            if (selectedTimeslot) {
                // this.triggerSearchTimeslot = '';
                this.timeslots.forEach((item, index) => {
                    if (item.id == selectedTimeslot) {
                        this.timeslot = item;
                    }
                });
            } else {
                this.triggerSearchTimeslot = "Therapist not available on this day";
            }
        });
    }

    setSelectedEvent(e) {
        // console.log(this.timeslot, e.target.value)
        this.selectedEvent = this.timeslot;
    }

    openSearchField() {
        this.openDoctorSearchField = true;
        this.model = null;
        this.selectedTherapist = null;
        this.timeslots = [];
    }

    getDoctorTitle(therapistData) {
        // console.log(therapistData.general_information.doctorRole);
        if (therapistData.general_information.doctorRole != "psychologist") {
            return "Dr.";
        }
        return "";
    }

    backBtn() {
        this.location.back();
    }

    medCertPrice() {
        if (this.selectedTherapist) {
            // console.log('this.selectedTherapist', this.selectedTherapist.addon_price_med_cert)
            switch (typeof this.selectedTherapist.addon_price_med_cert) {
                case "number":
                    this.checkboxMedCertEnable = true;
                    return `(+Php${this.selectedTherapist.addon_price_med_cert.toFixed(2)})`;

                default:
                    this.checkboxMedCertEnable = false;
                    return `<em class="text-muted">Does not issue ${label_MedicalCertificate(
                        this.selectedTherapist
                    )}</em>`;
            }
        }
    }

    onClickMedCert(e) {
        if (this.checkboxMedCertEnable) {
            this.appointment.add_ons.medical_certificate = e.target.checked;
        }
    }

    medAbstractPrice() {
        if (this.selectedTherapist) {
            switch (typeof this.selectedTherapist.addon_price_abstract_report) {
                case "number":
                    this.checkboxMedAbstractEnable = true;
                    return `(+Php${this.selectedTherapist.addon_price_abstract_report.toFixed(2)})`;

                default:
                    this.checkboxMedAbstractEnable = false;
                    return `<em class="text-muted">Does not issue ${label_MedicalAbstract(
                        this.selectedTherapist
                    )}</em>`;
            }
        }
    }

    onClickMedAbstract(e) {
        if (this.checkboxMedAbstractEnable) {
            this.appointment.add_ons.abstract_report = e.target.checked;
        }
    }

    getConsultationFee = () => {
        const price = formattedPrice(
            this.selectedTherapist.rate_charge,
            getDayOfWeek(this.selectedEvent.start_at.date),
            is30Minutes(moment(this.selectedEvent.start_at.date), moment(this.selectedEvent.end_at.date)),
            this.selectedEvent.offline ? "face" : "virtual"
        );

        if (price === null) {
            return `<span class="text-danger"><strong>Doctor did not set Price</strong></span>`;
        }
        return price;
    };

    displayDateRange = (selectedEvent) => {
        if (!selectedEvent) {
            selectedEvent = this.selectedEvent;
        }

        return `${this.getFullDay(selectedEvent.start_at)} ${this.getLocalTime(
            selectedEvent.start_at
        )}  - ${this.getEndTime(selectedEvent.end_at)}`;
    };

    displayConsultationType = (event) => {
        if (event.offline) {
            return 'FACE-TO-FACE CONSULTATION';
        }
        return 'ONLINE CONSULTATION';
    };

}

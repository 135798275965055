// `.env.json` is generated by the `npm run build` command
// import * as env from './.env.json';

export const environment = {
    production: false,
    env: 'staging',
    version: '1.0.0',
    serverUrl: 'https://testapi.recoveryhub.ph/api/',
    defaultLanguage: 'en-US',
    supportedLanguages: [
        'en-US',
        'fr-FR'
    ],
    API_HEADER_ACCEPT: 'application/x.metropsych.v1+json',
    API_HEADER_ACCEPT_v2: 'application/x.metropsych.v2+json',
    BASE_URL: '/',
    siteUrl: 'https://testapp.recoveryhub.ph/',
    altpay: 'https://test.oppwa.com',
    OT_KEY: '46190132',
    ws_url: 'https://testcomm.recoveryhub.ph',
    serverUrlV2: 'https://testapi.recoveryhub.ph/api',
    serverAdminUrlV2: 'https://testapi.recoveryhub.ph',
};
